<template>
    <div>
        <LoaderDefault v-if="isLoading" />

        <div v-else>
            <DefaultHeader2 :routeBack="'/News'" :titleHeader="'Editar noticia ' + dataNew.title" />

            <section class="content-form">
                <div class="content-inputs" id="content-inputs">
                    <label for="title_new" class="labelNew" id="number-pocess-label">Título de la noticia *</label>
                    <input v-model="dataNew.title" class="input inputNew" name="title_new" type="text"
                        placeholder="Título de la noticia" id="title_new" required>
                </div>

                <div class="content-inputs" id="content-inputs">
                    <label for="description_new" class="labelNew">Descripción de la noticia *</label>
                    <input v-model="dataNew.description" class="input inputNew" name="description_new" type="text"
                        placeholder="Descripción de la noticia" id="description_new" required>
                </div>

                <div class="content-inputs" id="content-inputs">
                    <label for="date_new" class="labelNew">Fecha *</label>
                    <input id="date_new" v-model="dataNew.date" class="input inputNew" name="date_new" type="date"
                        placeholder="fecha" required>
                </div>

            </section>
            <hr>
            <section class="sec-anexos" id="sec-anexos">
                <div class="header-sec-anexos">
                    <h1 class="title-section-anexos">Subir imagen de la noticia</h1>
                    <i class="fa-solid fa-circle-info"></i>
                </div>

                <div class="content-list-items-add">
                    <div v-for="(item, index) in dataNew.images" :key="index">
                        <div class="content-item">
                            <h2 class="name-item-added">{{ item.name }}</h2>
                            <div class="content-img">
                                <img class="image" :src="item.urlImage" alt="">
                            </div>
                            <div class="section-actions">
                                <button class="btn btn-warning" @click="showFormEdit(item, index)">Editar</button>
                                <button class="btn btn-danger" @click="showConfirmation(item)">Eliminar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn-save-item" @click="showModalInfo()">Nueva Imagen</button>
            </section>

            <a-modal v-model="visibleModalInfo" class="modal-plantilla" title="Añadir nuevo registro" @ok="saveNewImages()" cancel-text="Cancelar" ok-text="Guardar">
                <div class="edit-images">
                    <h2 class="title-update">Añadir Nueva Imagen</h2>

                    <div class="content-inputs2-edit-modal me-4" id="content-inputs">
                        <label class="label labelNew" id="label" for="namee">Nombre *</label>
                        <input id="namee" v-model="newDataDocumentNew.name" class="input inputNew" name="name" type="text"
                            placeholder="Nombre" required>
                    </div>
                    <div class="content-inputs2-edit-modal me-4" id="content-inputs">
                        <label class="label labelNew" id="label" for="descriptionn">Descripcion *</label>
                        <input id="descriptionn" v-model="newDataDocumentNew.description" class="input inputNew"
                            name="description" type="text" placeholder="description" required>
                    </div>

                    <div class="div-select-custom-modal">
                        <input id="file-5" type="file" name="file-5" class="inputfile inputfile-5 input-anexo" required
                            @click="loadFile" @change="loadFile()" />
                        <label id="label" for="file-5" class="label-file label">
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                    viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                    </path>
                                </svg>
                            </figure>
                        </label>
                        <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar imagen *</h6>
                    </div>
                </div>

            </a-modal>
            <hr>
            <button class="btn-save-data" @click="updateInfoToDatebase()">Actualizar
                noticia</button>
        </div>

        <section class="container-form-edit" id="container-form-edit"><span class="close-btn" @click="closeFormEdit()"><a
                    href="#">X</a></span>
            <div class="show">
                <h2 class="title-update">Actualizar documento </h2>
                <div class="content-form mt-4">
                    <div class="content-inputs2 me-4" id="content-inputs">
                        <label class="label" id="label" for="name">Nombre *</label>
                        <input id="name" v-model="newDataDocument.name" class="input " name="name" type="text"
                            placeholder="Nombre" required>
                    </div>
                    <div class="content-inputs2 me-4" id="content-inputs">
                        <label class="label" id="label" for="description">Descripcion *</label>
                        <input id="description" v-model="newDataDocument.description" class="input" name="description"
                            type="text" placeholder="description" required>
                    </div>
                </div>

                <div class="mt-2">
                    <h5>¿Desea cambiar el documento asociado</h5>
                    <div>
                        <div class="roundedTwo">
                            <label>
                                <input type="radio" name="opcion" value="si" @change="selectedOption = 'si'"> Sí
                            </label>

                            <label>
                                <input type="radio" name="opcion" value="no" checked @change="selectedOption = 'no'"> No
                            </label>
                        </div>
                    </div>
                </div>

                <div class="div-select-custom" v-if="selectedOption === 'si'">
                    <input id="file-5" type="file" name="file-5" class="inputfile inputfile-5 input-anexo" required
                        @click="loadFile" @change="loadFile()" />
                    <label id="label" for="file-5" class="label-file label">
                        <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                viewBox="0 0 20 17">
                                <path
                                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                </path>
                            </svg>
                        </figure>
                    </label>
                    <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar imagen *</h6>
                </div>
                <button class="btn-save-data mt-4" role="button" @click="editDocument()">Actualizar
                    elemento</button>
            </div>
        </section>


    </div>
</template>
<script>
import { db, storage } from '../../../../firebaseConfig';
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue';
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';

export default {
    components: {
        LoaderDefault,
        DefaultHeader2
    },
    data() {
        return {
            visibleModalInfo: false,
            isLoading: false,
            listNew: [],
            listNewDocuments: [],
            dataAction: [],
            selectedOption: 'no',
            gedNews: '',
            positionEdit: 0,
            selectedFile: File,
            idParam: "",
            newDataDocument: {
                _idName: "",
                name: "",
                description: "",
                urlImage: ""
            },
            newDataDocumentNew: {
                _idName: "",
                name: "",
                description: "",
                urlImage: ""
            },

            dataNew: {
                title: "",
                description: "",
                date: "",
                images: this.listNew
            },

        };
    },


    mounted() {
        this.idParam = this.$route.params._id
        this.getDataNew()
    },

    methods: {
        showModalInfo() {
            this.visibleModalInfo = true;
        },
        async getDataNew() {
            this.isLoading = true
            await db.collection('news')
                .doc(this.idParam)
                .get()
                .then((res) => {
                    this.dataNew = res.data();
                    this.listNew = res.data().images
                    this.isLoading = false
                })
        },

        async updateInfoToDatebase() {
            if (this.validateFormNew()) {
                this.isLoading = true
                await db.collection('news').doc(this.dataNew._id).set(this.dataNew)
                this.$swal
                    .fire({
                        title: 'Noticia actualizada',
                        text: 'Noticia actualizada correctamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    });
                this.isLoading = false
            }
        },


        async deleteImageNew(obj) {
            this.isLoading = true
            const docRef = db.collection('news')
                .doc(this.idParam);
            const doc = await docRef.get();
            const array = doc.get('images');
            const index = array.findIndex((item) => item._idName === obj._idName);
            if (index > -1) {
                array.splice(index, 1);
            }
            await docRef.update({ ['images']: array });
            await this.deleteFileStorage(obj._idName)
            await this.getDataNew()
            this.$swal
                .fire({
                    title: 'Acción exitosa',
                    text: 'Imagen eliminada exitosamente',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                })
            return this.getDataNew()

        },
        showConfirmation(doc_id) {
            this.$swal
                .fire({
                    title: 'Eliminar Imagen',
                    text: '¿Estás seguro de que deseas realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteImageNew(doc_id);
                });
        },

        async deleteFileStorage(nameFile) {
            const storageRef = storage.ref()
            const filePath = storageRef.child('imgNews')
                .child(this.idParam)
                .child(nameFile)
            try {
                await filePath.delete()
                await this.getDataNew()
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },

        showFormEdit(item, position) {
            this.newDataDocument = item
            this.positionEdit = position
            const layoutForm = document.getElementById('container-form-edit')
            layoutForm?.classList.remove('container-form-edit')
            layoutForm?.classList.add('container-form-edit-visible')
        },

        closeFormEdit() {
            const layoutForm = document.getElementById('container-form-edit')
            layoutForm?.classList.add('container-form-edit')
            layoutForm?.classList.remove('container-form-edit-visible')
        },
        async editDocument() {
            this.isLoading = true
            this.closeFormEdit()
            const docRef = db.collection('news').doc(this.idParam);
            const docEdit = await docRef.get();
            const data = docEdit.data().images;
            data[this.positionEdit] = this.newDataDocument

            if (this.selectedOption === "si") {
                if (this.selectedFile instanceof File && this.selectedFile.size > 0) {
                    const storageRef = await storage.ref()
                    const filePath = await storageRef.child('imgNews')
                        .child(this.idParam)
                        .child(data[this.positionEdit]._idName)
                    await filePath.delete()
                    const imgNewRef = storageRef.child('imgNews')
                        .child(this.idParam)
                        .child(data[this.positionEdit].name)

                    await imgNewRef.put(this.selectedFile)
                    const imgUrl = await imgNewRef.getDownloadURL()

                    data[this.positionEdit]._idName = this.newDataDocument.name
                    data[this.positionEdit].urlImage = imgUrl
                    await docRef.update({
                        "images": data
                    });
                    this.newDataDocument = {}
                    this.isLoading = false
                    this.$swal
                        .fire({
                            title: 'Acción exitosa',
                            text: 'Se ha actualizado el documento exitosamente',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                    await this.getDataNew()
                } else {
                    this.$swal
                        .fire({
                            title: 'Información importante',
                            text: 'Si desea cambiar la imagen, por favor seleccionar al menos un archivo nuevo',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                    this.isLoading = false
                }
            } else {
                await docRef.update({
                    "images": data
                });
                this.newDataDocument = {}
                this.isLoading = false
                this.$swal
                    .fire({
                        title: 'Acción exitosa',
                        text: 'Se ha actualizado el documento exitosamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.closeFormEdit()
                await this.getDataNew()
            }
        },
        loadFile() {
            const input = document.getElementById('file-5')
            input.accept = "image/*";
            input?.addEventListener('change', (e) => {
                const target = e.target
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        },

        async saveNewImages() {
            if (this.validateFormNew()) {

                const storageRef = storage.ref();
                const pdfRef = storageRef
                    .child('imgNews')
                    .child(this.idParam)
                    .child(this.newDataDocumentNew.name);
                const reader = new FileReader();
                reader.onload = async () => {
                    const pdfDataURL = reader.result;
                    await pdfRef.putString(pdfDataURL, 'data_url');
                    const pdfUrl = await pdfRef.getDownloadURL();
                    this.newDataDocumentNew._idName = this.newDataDocumentNew.name;
                    this.newDataDocumentNew.urlImage = pdfUrl;
                    this.listNew.push(this.newDataDocumentNew);
                    db.collection('news')
                        .doc(this.idParam)
                        .update({ images: this.listNew });
                    this.newDataDocumentNew = {};
                    this.getDataNew()
                };
                this.$swal
                    .fire({
                        title: 'Imagen Creada',
                        text: 'Imagen Creada correctamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    });
                this.isLoading = false
                reader.readAsDataURL(this.selectedFile);
            } else {
                // alert("Error")
            }
        },

        validateFormNew() {
            const inputs = document.querySelectorAll('.inputNew');
            const label = document.querySelectorAll('.labelNew');

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    label[i].style.color = ''
                }
            }
            return true;
        }

    },

}
</script>
<style>
.header-tender-anexo {
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.back-route {
    margin-left: 15px;
    margin-right: 30px;
}

.icon-back {
    font-size: 40px;
    cursor: pointer;
}

/* SECTION INPUTS FORM */
.content-form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

p {
    margin: 0;
    padding: 0;
}

.header-sec-anexos {
    display: flex;
    align-items: center;
}

.title-section-anexos {
    margin-right: 10px;
}

.content-inputs {
    width: 30%;
    /* background-color: blue; */
    margin: 5px;
    text-align: start;
    overflow: hidden;
    padding: 10px;
}

label {
    width: 100% important;
}

.input {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #4d9bf53d;
    outline: none;
    box-shadow: #d8d7d7 2px 4px 8px;
}

.input-date-anexo {
    margin-left: 160px;
}


.content-list-items-add {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;

}

.content-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    box-shadow: #d8d7d7 2px 4px 8px;
    margin-top: 15px;
}


.image {
    position: relative;
    width: 250px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 10px;
}

.section-actions {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

/* form nuevas imagen */

.edit-images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: black;
    border-radius: 10px;
}
</style>